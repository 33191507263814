import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import ButtonEditor from '../../ButtonEditor';
import Image from '../../../Image';
import styles from './styles.module.css';

class HeroSliderLayout extends React.PureComponent {

  static createStyle = (colorObj, colors) => {

    let backgroundColor = { solid: '#ffffff' };
    let opacity = 0;

    if (
      colorObj
      && colorObj.active
      && colorObj.solid
    ) {

      backgroundColor = { solid: colorObj.solid };
      ({ opacity } = colorObj);

    }

    const style = formColor(
      backgroundColor,
      false,
      opacity,
      undefined,
      colors,
    );

    return style;

  };

  constructor(props) {

    super(props);

    const dataIndex = props.section.data.findIndex(d => d.type === 'DATA');
    const rec = props.section;
    const showBullets = rec ? rec.data[dataIndex].showBullets : false;
    const showArrows = rec ? rec.data[dataIndex].showArrows : false;
    const interval = rec ? rec.data[dataIndex].interval * 1000 : 4000;
    const autoplayEnabled = rec ? rec.data[dataIndex].autoplayEnabled : false;
    const loopEnabled = rec ? rec.data[dataIndex].loopEnabled : false;
    const slideTransition = rec ? rec.data[dataIndex].slideTransition : 'noanimation';

    const slides = [];
    if (props.section && props.section.data) {

      props.section.data.forEach(dataSlot => {

        if (dataSlot.type === 'COMPONENT') slides.push(dataSlot);

      });

    }

    const playerId = Math.random().toString(36).substr(2, 10);

    this.state = {
      currentSlideIndex: 0,
      videoLoaded: false,
      slides,
      showBullets,
      showArrows,
      interval,
      autoplayEnabled,
      loopEnabled,
      slideTransition,
      playerId,
    };

    this.renderSlide = this.renderSlide.bind(this);
    this.renderDots = this.renderDots.bind(this);
    this.handleDotClick = this.handleDotClick.bind(this);
    this.renderArrows = this.renderArrows.bind(this);
    this.handleArrowClick = this.handleArrowClick.bind(this);
    this.handleInterval = this.handleInterval.bind(this);
    this.handleMessageReceived = this.handleMessageReceived.bind(this);

  }

  componentDidMount() {

    this.handleInterval();

    if (typeof window !== 'undefined' && window.addEventListener) {

      window.addEventListener('message', this.handleMessageReceived, false);

    }

  }

  componentWillUnmount() {

    if (typeof window !== 'undefined' && window.removeEventListener) {

      window.removeEventListener('message', this.handleMessageReceived, false);

    }

  }

  handleMessageReceived(e) {

    if (e && e.origin && e.origin === 'https://player.vimeo.com' && e.data) {

      const data = JSON.parse(e.data);
      if (data.player_id === this.state.playerId
        && data.event === 'ready') this.setState({ videoLoaded: true });

    }

  }

  handleInterval() {

    if (this.state.autoplayEnabled) {

      let newIndex;
      let end = false;

      if (this.state.loopEnabled) {

        newIndex = ((this.state.currentSlideIndex + 1) > this.state.slides.length - 1)
          ? 0
          : this.state.currentSlideIndex + 1;

      } else {

        if ((this.state.currentSlideIndex + 1) > this.state.slides.length - 1) {

          end = true;
          newIndex = this.state.currentSlideIndex;

        } else {

          newIndex = this.state.currentSlideIndex + 1;

        }

      }

      if (!end) {

        setTimeout(
          () => {

            this.setState({
              currentSlideIndex: newIndex,
              videoLoaded: false,
              prevSlideIndex: this.state.currentSlideIndex,
              alternate: !this.state.alternate,
            }, () => {

              this.handleInterval();

            });


          }, this.state.interval,

        );

      }

    }

  }

  handleDotClick(index) {

    this.setState({
      currentSlideIndex: index,
      videoLoaded: false,
      prevSlideIndex: this.state.currentSlideIndex,
      alternate: !this.state.alternate,
    });

  }

  renderSlide(slide) {

    if (!slide || !slide.data || slide.data.length !== 5) return null;

    const align = slide && slide.data && slide.data[4] && slide.data[4].align
      ? slide.data[4].align
      : 'Left';

    const headerAlignStyle = styles[`heroHeader${align}`];
    const paragraphAlignStyle = styles[`heroParagraph${align}`];
    const exceptionStyle = styles[`heroException${this.props.themeData.typography.default.fontSize}`];
    const btnWrapper = styles[`heroSliderBtnWrapper${align}`];

    const buttons = [];
    const texts = [];
    slide.data.forEach((item, index) => {

      if (item.active) {

        let text;
        let type;
        if (item.type.startsWith('HEADINGS') || item.type.startsWith('PARAGRAPH')) {

          if (item.type === 'HEADINGS/HEADING-ONE') {

            text = `<h1>${item.text}</h1>`;
            type = 'h1';

          } else if (item.type === 'PARAGRAPH/PARAGRAPH') {

            text = `<span>${item.text}</span>`;
            type = 'p';

          }

        }

        if (text !== undefined) {

          const res = (
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={
                type === 'h1'
                  ? headerAlignStyle
                  : `${paragraphAlignStyle} ${exceptionStyle}`
              }
            >
              {HTMLParser(text)}
            </div>
          );

          texts.push(res);

        } else if (item.type.startsWith('BUTTON')) {

          buttons.push((
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={styles.btnWrapper}
            >
              <ButtonEditor
                buttons={this.props.buttons}
                themeData={this.props.themeData}
                button="Button"
                data={item.content}
                pagePathList={this.props.pagePathList}
                articlePathList={this.props.articlePathList}
                filePathList={this.props.filePathList}
              />
            </div>
          ));

        }

      }

      return null;

    });

    const slideElement = (
      <div style={{ width: '100%' }}>
        <div className="col-12 col-sm-10 offset-sm-1">
          { texts }
          <div className={btnWrapper}>
            {buttons}
          </div>
        </div>
      </div>
    );

    return slideElement;

  }

  renderDots() {

    if (this.state.showBullets) {

      const dots = [];

      this.state.slides.map((slide, index) => {

        const dot = (
          <span
            key={`heroSlider_dot_${index}`}
            className={this.state.currentSlideIndex === index ? styles.dotActive : styles.dot}
            onClick={() => this.handleDotClick(index)}
          />
        );

        dots.push(dot);

        return dot;

      });

      const dotsElement = (
        <div className={`col-12 ${styles.dotsWrapper}`}>
          {dots}
        </div>
      );

      return dotsElement;

    }

    return null;

  }

  handleArrowClick(value) {

    this.setState({
      currentSlideIndex: value,
      videoLoaded: false,
      prevSlideIndex: this.state.currentSlideIndex,
      alternate: !this.state.alternate,
    });

  }

  renderArrows() {

    if (this.state.showArrows) {

      const arrows = [];

      if (this.state.currentSlideIndex > 0) {

        const arrowLeft = (
          <span
            key="arrowLeft"
            className={`entypo icon-left-open ${styles.arrowLeft}`}
            onClick={() => this.handleArrowClick(this.state.currentSlideIndex - 1)}
          />
        );

        arrows.push(arrowLeft);

      }

      if (this.state.currentSlideIndex < this.state.slides.length - 1) {

        const arrowRight = (
          <span
            key="arrowRight"
            className={`entypo icon-right-open ${styles.arrowRight}`}
            onClick={() => this.handleArrowClick(this.state.currentSlideIndex + 1)}
          />
        );

        arrows.push(arrowRight);

      }

      const arrowElements = (
        <React.Fragment>
          {arrows}
        </React.Fragment>
      );

      return arrowElements;

    }

    return null;

  }

  createSlide(slideIndex, addPadding) {

    let videoURL = '';
    let bgStyle;
    let videoOverlay;
    let image;
    if (this.state.slides[slideIndex]
      && this.state.slides[slideIndex].data[4]) {

      ({ videoURL } = this.state.slides[slideIndex].data[4]);

      if (this.state.slides[slideIndex].data[4].styles
        && this.state.slides[slideIndex].data[4].styles.bg
        && this.state.slides[slideIndex].data[4].styles.bg.active) {

        bgStyle = HeroSliderLayout.createStyle(this.state
          .slides[slideIndex].data[4].styles.bg, this.props.themeData.colors);

      }

      if (
        this.state.slides[slideIndex].data[4].styles
        && this.state.slides[slideIndex].data[4].styles.video
        && this.state.slides[slideIndex].data[4].styles.video.active
      ) {

        videoOverlay = HeroSliderLayout.createStyle(this.state
          .slides[slideIndex].data[4].styles.video, this.props.themeData.colors);

      }

      if (
        (
          this.state.slides[slideIndex].data[4].image.active
          && this.state.slides[slideIndex].data[4].image.id)
        || (
          this.state.slides[slideIndex].data[4].styles
          && this.state.slides[slideIndex].data[4].styles.backgroundColor
          && this.state.slides[slideIndex].data[4].styles.backgroundColor.active
        )
      ) {

        let img;
        let bgOverlay;
        if (
          this.state.slides[slideIndex].data[4].styles
          && this.state.slides[slideIndex].data[4].styles.backgroundColor
          && this.state.slides[slideIndex].data[4].styles.backgroundColor
            .active
        ) {

          bgOverlay = HeroSliderLayout.createStyle(this.state
            .slides[slideIndex].data[4].styles.backgroundColor, this.props.themeData.colors);

        }

        if (this.state.slides[slideIndex].data[4].image.active
          && this.state.slides[slideIndex].data[4].image.id) {

          img = (
            <Image
              id={this.state.slides[slideIndex].data[4].image.id}
              alt=""
              imageClass="heroSliderBackgroundImage"
              sizes="100vw"
              images={this.props.images}
            />
          );

        }

        image = (
          <React.Fragment>
            { img }
            {
              bgOverlay
              && (
                <div
                  className={styles.videoOverlay}
                  style={bgOverlay}
                />
              )
            }
          </React.Fragment>
        );

      }

    }

    let vimeoURL;
    if (videoURL) {

      const { startsAtMin } = this.state.slides[slideIndex].data[4] || 0;
      const { startsAtSec } = this.state.slides[slideIndex].data[4] || 0;
      const loopVideo = this.state.slides[slideIndex].data[4].loopVideo ? 1 : 0;
      // eslint-disable-next-line
      const regExp = /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      const match = videoURL.match(regExp);

      let video;
      if (match && match[1]) [, video] = match;
      if (video) vimeoURL = `https://player.vimeo.com/video/${video}?background=true&api=1&responsive=true&loop=${loopVideo}&player_id=${this.state.playerId}#t=${startsAtMin}m${startsAtSec}s`;

    }

    if (
      (
        image
        || (
          this.state.slides[slideIndex]
          && this.state.slides[slideIndex].data
          && this.state.slides[slideIndex].data[4]
          && this.state.slides[slideIndex].data[4].loadingImage
          && this.state.slides[slideIndex].data[4].loadingImage.active
          && this.state.slides[slideIndex].data[4].loadingImage.id
        ))
      && vimeoURL
      && !this.state.videoLoaded
    ) {

      if (
        this.state.slides[slideIndex].data[4].loadingImage.active
        && this.state.slides[slideIndex].data[4].loadingImage.id
      ) {

        image = (
          <div className={styles.loadingWrapper}>
            <Image
              id={this.state.slides[slideIndex].data[4].loadingImage.id}
              alt=""
              imageClass="heroSliderBackgroundImage"
              sizes="100vw"
              images={this.props.images}
            />
            {
              videoOverlay
              && (
                <div
                  className={styles.videoOverlay}
                  style={videoOverlay}
                />
              )
            }
          </div>
        );

      }

    }

    let video;
    if (vimeoURL) {

      video = (
        <React.Fragment>
          <div className={styles.frameWrapper}>
            {
              slideIndex === this.state.currentSlideIndex
              && (
                <iframe
                  className={styles.videoFrame}
                  src={vimeoURL}
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  frameBorder="0"
                />
              )
            }
          </div>
          {
            videoOverlay
            && (
              <div
                className={styles.videoOverlay}
                style={videoOverlay}
              />
            )
          }
        </React.Fragment>
      );

    }

    let slide = this.renderSlide(this.state.slides[slideIndex], slideIndex);
    if (addPadding && this.props.padding) {

      slide = (
        <div style={{ paddingTop: this.props.padding, paddingBottom: '60px', width: '100%' }}>
          { slide }
        </div>
      );

    }

    return (
      <React.Fragment key={`${this.props.section._id}_Slide_${slideIndex}`}>
        <div className={styles.background} style={bgStyle}>
          { video }
          { !this.state.videoLoaded && image }
        </div>
        { slide }
      </React.Fragment>
    );

  }

  createSlides() {

    const slides = [];
    if (this.state.slideTransition === 'noanimation' || this.state.slides.length === 1 || this.state.prevSlideIndex === undefined) {

      slides.push(this.createSlide(this.state.currentSlideIndex));

    } else {

      const indices = [this.state.prevSlideIndex, this.state.currentSlideIndex];

      indices.forEach(i => {

        let slideStyle;
        if (i === this.state.currentSlideIndex) slideStyle = `top${this.state.alternate ? 'Alt' : ''}`;
        else if (i === this.state.prevSlideIndex) slideStyle = `bottom${this.state.alternate ? 'Alt' : ''}`;
        slides.push(<div key={`${this.props.section._id}_Slide_${slideStyle}`} className={styles[slideStyle]}>{this.createSlide(i, true)}</div>);

      });

    }

    return slides;

  }

  render() {

    const slides = this.createSlides();

    return (
      <div className={styles.wrapper}>
        { slides }
        { this.renderArrows() }
        { this.renderDots() }
      </div>
    );

  }

}

HeroSliderLayout.propTypes = {
  mobileView: PropTypes.bool,
  domain: PropTypes.string,
  page: PropTypes.string,
  align: PropTypes.string,
  theme: PropTypes.string,
  showBullets: PropTypes.bool,
  showArrows: PropTypes.bool,
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  active: PropTypes.string,
  onTextClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  onBlur: PropTypes.func,
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      navigation: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      button: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
  source: PropTypes.string,
  layout: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HeroSliderLayout;
